define('ember-cli-ifa/initializers/asset-map', ['exports', 'rsvp', 'jquery', 'ember-cli-ifa/services/asset-map'], function (exports, _rsvp, _jquery, _emberCliIfaServicesAssetMap) {
  exports.initialize = initialize;

  function initialize(app) {
    app.deferReadiness();

    var assetMapFile = window && window.__assetMapFilename__;

    if (!assetMapFile) {
      app.register('service:asset-map', _emberCliIfaServicesAssetMap['default']);
      app.advanceReadiness();
      return;
    }

    var promise = new _rsvp['default'].Promise(function (resolve, reject) {
      _jquery['default'].getJSON(assetMapFile, resolve).fail(reject);
    });

    promise.then(function () {
      var map = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      _emberCliIfaServicesAssetMap['default'].reopen({
        map: map.assets,
        prepend: map.prepend,
        enabled: true
      });
    }).then(function () {
      app.register('service:asset-map', _emberCliIfaServicesAssetMap['default']);
      app.advanceReadiness();
    });
  }

  exports['default'] = {
    name: 'asset-map',
    initialize: initialize
  };
});
/* global window, __assetMapFilename__ */