define('ember-cli-ifa/services/asset-map', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    enabled: false,
    map: {},
    prepend: '/',

    resolve: function resolve(name) {
      var map = this.get('map') || {};
      var prepend = this.get('prepend');
      var enabled = this.get('enabled');
      var assetName = enabled ? map[name] : name;

      return '' + prepend + assetName;
    }
  });
});