define('ember-cli-ifa/helpers/asset-map', ['exports', 'ember', 'ember-service/inject'], function (exports, _ember, _emberServiceInject) {
  exports['default'] = _ember['default'].Helper.extend({
    assetMap: (0, _emberServiceInject['default'])('asset-map'),

    compute: function compute(params) {
      var file = params[0] || "";

      if (!file) {
        return;
      }

      return this.get('assetMap').resolve(file);
    }
  });
});